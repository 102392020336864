import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.search()
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.gtranslate()
    }

    static search() {
        $(document).on('click', NavbarEnum.SEARCH_TRIGGER, () => {
            $(NavbarEnum.SEARCH_INPUT).focus()
        })
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 150 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 1024px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    } else {
                        NavbarComponent.changeLogoColor('classic')
                    }
                }
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function (e) {
            if (window.matchMedia('(max-width: 1024px)').matches) {
                e.preventDefault()
            }
            $(this).siblings(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
            $('body').addClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $('body').removeClass('submenu-open')
        })
    }

    static gtranslate() {
        $(window).on('load', () => {
            setTimeout(() => {
                const current_lang = $('html').attr('lang').replace(/-.*/gi, '')
                const current_list_item = `[data-gt-lang=${current_lang}]`

                if (current_lang !== $(NavbarEnum.LANG_CURRENT).text()) {
                    $(NavbarEnum.LANG_CURRENT).text(current_lang).text()
                }

                if ($(current_list_item).attr('href') === '#') {
                    $(current_list_item).hide()
                }
            }, 250)
        })

        $(NavbarEnum.LANG_ITEM).on('click', function () {
            if ($(this).attr('href') === '#') {
                // Free version
                $(NavbarEnum.LANG_ITEM).show()
                $(this).hide()
                $(NavbarEnum.LANG_CURRENT).text($(this).text())
            }
        })
    }
}
