export default class BreadcrumbsComponent {
    constructor() {
        BreadcrumbsComponent.show()
    }

    static show() {
        const breadcrumbs = '#breadcrumbs'

        if (breadcrumbs.length > 0 && window.matchMedia('(max-width: 1024px)').matches) {
            // On click
            $(breadcrumbs).on('click', (e) => {
                if (e.target.tagName !== 'A') {
                    $(breadcrumbs).toggleClass('breadcrumbs--show')
                }
            })
        }
    }
}
