export default class NavbarEnum {
    static BODY = 'body'

    static NAVBAR = '[data-element="navbar"]'

    static SEARCH_TRIGGER = '[href="#modal-wp-search"]'
    static SEARCH_INPUT = '#modal-wp-search input[type="text"]'

    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'

    static LANG_CURRENT = '[data-id="wpiris_lang-current"]'
    static LANG_ITEM = '[data-id="wpiris_lang-list"] a'
}
